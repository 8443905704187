import { HttpClient } from "../HttpClient";
import { isVVV } from "@/helpers/siteIdentifier";
/**
 * * Post Invite User
 */
export const PostInviteUser = async (userData) => {
  try {
    const currentDashboard = isVVV() ? 3 : 2;
    const { data } = await HttpClient.post("api/users/invite", {
      ...userData,
      currentDashboard,
    });
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
